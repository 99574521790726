import React from 'react';
import {
  Button,
  StackLayout,
  HeadingText,
  ParagraphText,
  styled,
  StackItem,
} from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';
import { SignInButtonProps } from './landing.page.types';

const LogoImage = styled('img', {
  width: '433px',
  '@mobile': {
    width: '220px',
  },
  '@tablet': {
    width: '333px',
  },
  '@mobileLandscape': {
    width: '220px',
  },
});

export const SignInSection = ({
  logo,
  errorMessage,
  signInButton,
}: {
  logo: string;
  errorMessage?: string | null;
  signInButton: SignInButtonProps;
}) => {
  const { formatMessage } = useIntl();
  return (
    <StackLayout
      orientation="vertical"
      horizontalAlignment="center"
      verticalAlignment={{
        '@initial': 'center',
        '@mobile': 'bottom',
      }}
    >
      <StackItem horizontalAlignment="center" verticalAlignment="center">
        <HeadingText size="lg" level="1" css={{ marginBottom: '$three' }}>
          <LogoImage src={logo} alt={formatMessage({ id: 'STR_APP_NAME' })} />
        </HeadingText>
        <Button
          size="large"
          priority="primary"
          width="fillContainer"
          css={{
            maxWidth: 243,
            ...signInButton?.css,
          }}
          onClick={signInButton.onClick}
        >
          {signInButton.buttonText}
        </Button>
        {errorMessage && (
          <ParagraphText
            css={{ color: '$onSurfaceTextCritical', margin: '$one' }}
          >
            {errorMessage}
          </ParagraphText>
        )}
      </StackItem>
    </StackLayout>
  );
};
