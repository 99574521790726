import React from 'react';
import { UtilityText } from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';
import { EXTERNAL_LINKS } from '../../common/links';

export const TermAndConditionsText = () => {
  const { formatMessage } = useIntl();

  return (
    <UtilityText>
      {formatMessage({ id: 'I_HAVE_READ' })}{' '}
      <a href={EXTERNAL_LINKS.PRIVACY_POLICY} target="_blank" rel="noreferrer">
        {formatMessage({ id: 'PRIVACY_POLICY' })}
      </a>{' '}
      {formatMessage({ id: 'AND' })}{' '}
      <a href={EXTERNAL_LINKS.TERMS_OF_USE} target="_blank" rel="noreferrer">
        {formatMessage({ id: 'TERMS_OF_USE' })}
      </a>
      .
    </UtilityText>
  );
};
