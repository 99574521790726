import { Footer } from '@xo-health-web/shared-module';
import { useAppFooterLinks } from './use-app-footer-links.hook';

export const AppFooter = () => {
  const { footerLinks, secondaryFooterLinks, appDownloadLinks } =
    useAppFooterLinks();
  return (
    <Footer
      footerLinks={footerLinks}
      secondaryFooterLinks={secondaryFooterLinks}
      footerAppDownloadAssets={appDownloadLinks}
      backgroundColor="$decorativeBrandPrimaryPastel"
      textColor="$interactiveActionPrimary"
    />
  );
};
