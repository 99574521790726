import React from 'react';
import { UserDropdownComponent } from '@leagueplatform/web-common-components';
import {
  EVENT_NAME,
  PRODUCT_AREA,
  SCREEN_NAMES,
} from '@leagueplatform/analytics';
import { useUserDropdownComponent } from './user-dropdown.hook';

interface Props {
  handleDeleteAccount: () => void;
}
const userDropdownAnalytics = {
  name: EVENT_NAME.NAVIGATION_BAR_CLICKED,
  props: {
    product_area: PRODUCT_AREA.HOME_SCREEN,
    screen_name: SCREEN_NAMES.HOME_SCREEN,
    detail: 'drop down menu',
  },
};

export const UserDropdown = React.memo(({ handleDeleteAccount }: Props) => {
  const { data } = useUserDropdownComponent(handleDeleteAccount);

  return (
    <UserDropdownComponent
      label={data.preferredFirstName.toUpperCase()}
      dropdownItemsConfig={data.menuItemsConfig.map((section) => section.items)}
      userBarAnalyticsProps={userDropdownAnalytics}
    />
  );
});
