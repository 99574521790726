import { Suspense } from 'react';
import { BrowserRouter } from '@leagueplatform/routing';
import { ReactQueryDevtools } from 'react-query/devtools';
import {
  ErrorState,
  LoadingSpinner,
  ERROR_TYPES,
} from '@xo-health-web/shared-module';

import { useOnlineStatus } from '@leagueplatform/web-common';
import League from '@leagueplatform/core';
import { ObservabilityErrorBoundary } from '@leagueplatform/observability';
import { HealthJourneyHome } from '@leagueplatform/health-journey';
import { MasonryWSDriverNodeRenderersRegistry } from '@leagueplatform/masonry';
import { RecentClaimsCardList } from '@leagueplatform/claims';
import { MASONRY } from 'common/constants';
import AppRoutes from './components/AppRoutes';
import { config, initializeNonConfigSDKs } from './init-sdk-config';

initializeNonConfigSDKs();

MasonryWSDriverNodeRenderersRegistry.registerRenderer(
  MASONRY.MODULE_KEY_CLAIMS_HOMEPAGE,
  MASONRY.CUSTOM_WIDGET_TYPE_CLAIMS,
  RecentClaimsCardList,
);

MasonryWSDriverNodeRenderersRegistry.overrideRenderer(
  MASONRY.MODULE_KEY_HEALTH_JOURNEY,
  MASONRY.CUSTOM_WIDGET_TYPE_HEALTH_JOURNEY,
  HealthJourneyHome,
);

export function App() {
  const { isOffline, isOnline } = useOnlineStatus();
  return (
    <League.LeagueProvider config={config}>
      <ObservabilityErrorBoundary
        fallback={<ErrorState isFullPage errorType={ERROR_TYPES.API_ERROR} />}
        errorContext={{
          context: { Context: { location: 'Root App ErrorBoundary' } },
        }}
      >
        <Suspense fallback={<LoadingSpinner />}>
          {isOffline && (
            <ErrorState
              isFullPage
              errorType={ERROR_TYPES.NO_CONNECTION}
              hideButton
            />
          )}
          {isOnline && (
            <BrowserRouter>
              <AppRoutes />
            </BrowserRouter>
          )}
          <ReactQueryDevtools />
        </Suspense>
      </ObservabilityErrorBoundary>
    </League.LeagueProvider>
  );
}
