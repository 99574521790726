import React from 'react';
import { Button, StackLayout, styled } from '@leagueplatform/genesis-core';

const FooterSubmitContainer = styled('div', {
  position: 'fixed',
  left: '0',
  bottom: '0',
  width: '100%',
  backgroundColor: '$surfaceBackgroundPrimary',
  boxShadow: '0px -2px 4px rgba(0, 0, 0, 0.1)',
});

export const FixedFormFooter = ({
  allowSubmit,
  isLoading,
  submitText,
  handleInfoExit,
}: {
  allowSubmit?: boolean;
  isLoading?: boolean;
  submitText: string;
  handleInfoExit?: () => void;
}) => (
  <FooterSubmitContainer>
    <StackLayout
      orientation={{ '@initial': 'horizontal', '@mobile': 'vertical' }}
      verticalAlignment="center"
      horizontalAlignment={{ '@initial': 'start', '@mobile': 'stretch' }}
      css={{
        paddingInline: '$twoAndHalf',
        maxWidth: 1200,
        marginInline: 'auto',
        paddingBlock: '$one',
        '@mobile': {
          paddingInline: '$oneAndHalf',
          paddingBlock: '$oneAndHalf $one',
        },
      }}
    >
      <StackLayout
        orientation={{ '@initial': 'horizontal', '@mobile': 'vertical' }}
        horizontalAlignment={{ '@initial': 'end', '@mobile': 'stretch' }}
        spacing="$one"
      >
        <Button
          loading={isLoading}
          type="submit"
          disabled={!allowSubmit}
          onClick={handleInfoExit}
          width={{ '@initial': 'hugContents', '@mobile': 'fillContainer' }}
        >
          {submitText}
        </Button>
      </StackLayout>
    </StackLayout>
  </FooterSubmitContainer>
);
