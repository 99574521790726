import React from 'react';
import { Box, UtilityText } from '@leagueplatform/genesis-core';

type UserProfileAttributeLabelProps = {
  name: string;
  value: string | undefined;
};

export function UserProfileAttributeLabel({
  name,
  value,
}: UserProfileAttributeLabelProps) {
  return (
    <Box>
      <Box>
        <UtilityText emphasis="emphasized" size="sm">
          {name}
        </UtilityText>
      </Box>
      <Box>
        <UtilityText emphasis="subtle">
          {Boolean(value) === false ? '-' : value}
        </UtilityText>
      </Box>
    </Box>
  );
}
