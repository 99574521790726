import {
  advocateCommunicationPreference,
  GET_ADVOCATTE_COMMUNICATION_PREFERENCE,
} from 'api/advocate-communication-preference';
import { useQuery } from 'react-query';

export function useAdvocateCommunicationPreference() {
  const query = useQuery([GET_ADVOCATTE_COMMUNICATION_PREFERENCE], () =>
    advocateCommunicationPreference(),
  );

  return query;
}
