import * as React from 'react';
import {
  HeadingText,
  StackLayout,
  UtilityText,
} from '@leagueplatform/genesis-core';
import { AdvocateCommunicationPreferenceProps } from '../../../common/types';

export const AdvocateCommunicationPreferences = ({
  advocateCommunicationPreference,
}: AdvocateCommunicationPreferenceProps) => (
  <StackLayout spacing="$half">
    <HeadingText
      level="2"
      size="sm"
      css={{
        fontWeight: '$subtitleOne',
        fontSize: '$subtitleOne',
        lineHeight: '$subtitleOne',
      }}
    >
      {advocateCommunicationPreference.sectionName}
    </HeadingText>
    <StackLayout spacing="$threeQuarters">
      <UtilityText
        as="p"
        css={{
          fontWeight: '$bodyOne',
          fontSize: '$bodyOne',
          lineHeight: '$bodyOne',
          textTransform: 'capitalize',
          color: '$onSurfaceTextSubdued',
        }}
      >
        {advocateCommunicationPreference.communicationMethodCode}
      </UtilityText>
      <UtilityText
        as="p"
        css={{
          fontWeight: '$label',
          fontSize: '$label',
          lineHeight: '$label',
        }}
      >
        {advocateCommunicationPreference.description}
      </UtilityText>
    </StackLayout>
  </StackLayout>
);
