import React, { useEffect } from 'react';
import { useHistory } from '@leagueplatform/routing';
import { useAuth } from '@leagueplatform/auth-utils';
import {
  StackLayout,
  queryHelpers,
  useMediaQuery,
  Box,
} from '@leagueplatform/genesis-core';
import { LandingPageProps } from './landing.page.types';
import { SignInSection } from './sign-in-section.component';
import { LoadingSpinner } from '../loading-spinner.component';

const SIGN_IN_SECTION_MOBILE_MIN_HEIGHT = '290px';

export const LandingPage = ({
  backgroundImage,
  backgroundMobileImage,
  logoImage,
  primaryButton,
  onAuthenticated,
  onAuthError,
  shouldDisplayErrorCaption = false,
}: LandingPageProps) => {
  const { isAuthenticated, appState, error } = useAuth();
  const history = useHistory();

  const isMobile = useMediaQuery(queryHelpers.down('mobileLandscape'));

  useEffect(() => {
    if (isAuthenticated) {
      onAuthenticated(appState);
    }
  }, [history, isAuthenticated, appState, onAuthenticated]);

  useEffect(() => {
    if (error) {
      onAuthError(error);
    }
  }, [error, onAuthError]);

  return (
    <StackLayout
      verticalAlignment="stretch"
      horizontalAlignment="stretch"
      css={{
        minWidth: '100vw',
        minHeight: '100vh',
      }}
    >
      {isAuthenticated === undefined && (
        <LoadingSpinner logo={logoImage} altText="XO_HEALTH_LOGO" />
      )}
      {isAuthenticated !== undefined && (
        <>
          {!isMobile && (
            <StackLayout
              verticalAlignment="center"
              horizontalAlignment="center"
              orientation={{
                '@initial': 'horizontal',
              }}
              css={{
                minWidth: '100%',
                minHeight: '100%',
              }}
            >
              <Box
                css={{
                  backgroundImage: `url(${backgroundImage})`,
                  width: '40%',
                  height: '100svh',
                  backgroundPosition: 'right 50%',
                  backgroundSize: 'cover',
                  '@tablet': {
                    width: '40%',
                  },
                }}
              />
              <StackLayout horizontalAlignment="center">
                <SignInSection
                  logo={logoImage}
                  errorMessage={
                    shouldDisplayErrorCaption ? error?.description : null
                  }
                  signInButton={primaryButton}
                />
              </StackLayout>
            </StackLayout>
          )}
          {isMobile && (
            <StackLayout css={{ height: '100%', width: '100%' }}>
              <StackLayout
                horizontalAlignment="center"
                css={{
                  width: '100%',
                  height: '30%',
                  minHeight: SIGN_IN_SECTION_MOBILE_MIN_HEIGHT,
                }}
              >
                <SignInSection
                  logo={logoImage}
                  errorMessage={
                    shouldDisplayErrorCaption ? error?.description : null
                  }
                  signInButton={primaryButton}
                />
              </StackLayout>
              <Box css={{ height: '70svh', width: '100%' }}>
                <Box
                  css={{
                    backgroundImage: `url(${backgroundMobileImage})`,
                    width: '100%',
                    height: '100%',
                    backgroundPosition: '50% top',
                    backgroundSize: 'cover',
                  }}
                />
              </Box>
            </StackLayout>
          )}
        </>
      )}
    </StackLayout>
  );
};
