import {
  EXTERNAL_LINKS,
  FooterLinkProps,
  AssetLinkProps,
} from '@xo-health-web/shared-module';
import { useIntl } from '@leagueplatform/locales';
import GooglePlayBadge from 'assets/google-play-badge.svg';
import AppleStoreBadge from 'assets/apple-store-badge.svg';
import { APP_MANAGED_ROUTES } from 'common/app-managed-routes';

// TODO: GET Links for app stores
const APP_DOWNLOAD_ASSET_LINKS: AssetLinkProps[] = [
  {
    src: AppleStoreBadge,
    url: '',
    alt: 'apple store app download link',
  },
  {
    src: GooglePlayBadge,
    url: '',
    alt: 'google play app download link',
  },
];

export const useAppFooterLinks = () => {
  const { formatMessage } = useIntl();

  const footerLinks: FooterLinkProps[] = [
    {
      text: formatMessage({ id: 'HOME' }),
      url: APP_MANAGED_ROUTES.HOME,
    },
    {
      text: formatMessage({ id: 'WALLET' }),
      url: APP_MANAGED_ROUTES.WALLET,
    },
    {
      text: formatMessage({ id: 'CARE' }),
      url: APP_MANAGED_ROUTES.GET_CARE,
    },
    {
      text: formatMessage({ id: 'JOURNEY' }),
      url: APP_MANAGED_ROUTES.HEALTH_JOURNEY,
    },
    {
      text: formatMessage({ id: 'ADVOCATE' }),
      url: APP_MANAGED_ROUTES.ADVOCATE,
    },
  ];

  const secondaryFooterLinks: FooterLinkProps[] = [
    {
      text: formatMessage({ id: 'ABOUT_XO_HEALTH' }),
      url: EXTERNAL_LINKS.ABOUT_XO_HELATH,
    },
    {
      text: formatMessage({ id: 'PRIVACY_POLICY' }),
      url: EXTERNAL_LINKS.PRIVACY_POLICY,
    },
    {
      text: formatMessage({ id: 'TERMS_OF_SERVICE' }),
      url: EXTERNAL_LINKS.TERMS_OF_USE,
    },
  ];

  return {
    footerLinks,
    secondaryFooterLinks,
    appDownloadLinks: APP_DOWNLOAD_ASSET_LINKS,
  };
};
