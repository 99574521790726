import React from 'react';
import {
  Spinner,
  StackLayout,
  Image,
  StackItem,
} from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';

export const LoadingSpinner = ({
  logo,
  altText,
}: {
  logo?: string;
  altText?: string;
}) => {
  const { formatMessage } = useIntl();

  return (
    <StackLayout
      horizontalAlignment="center"
      verticalAlignment="center"
      css={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      }}
    >
      <StackLayout horizontalAlignment="center" verticalAlignment="center">
        {logo ? (
          <StackItem>
            <Image src={logo} alt={formatMessage({ id: altText })} />
          </StackItem>
        ) : null}
        <StackItem>
          <Spinner
            thickness={3}
            size="$three"
            tint="$decorativeBrandSecondaryDark"
            loading
          />
        </StackItem>
      </StackLayout>
    </StackLayout>
  );
};
