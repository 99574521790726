import React, { useEffect } from 'react';
import { StandaloneAuth } from '@leagueplatform/auth-standalone';
import { LoadingSpinner } from '@xo-health-web/shared-module';
import logoWithText from 'assets/logo-with-text.png';

export const AuthSignIn = () => {
  useEffect(() => {
    StandaloneAuth.client.loginWithRedirect();
  }, []);

  return <LoadingSpinner logo={logoWithText} altText="XO_HEALTH_LOGO" />;
};
