import React from 'react';
import { CONTENT_MAX_WIDTH } from '@leagueplatform/web-common-components';
import { useIntl } from '@leagueplatform/locales';
import {
  GDSColor,
  ParagraphText,
  StackLayout,
} from '@leagueplatform/genesis-core';
import { AssetLink, AssetLinkProps } from '../asset-links/asset-link.component';
import { FooterLinkProps } from './footer-items/footer-link-items.component';
import { FooterLinksAndIconsSection } from './footer-items/footer-links-container.component';

const FooterLayoutWrapper = ({
  children,
  backgroundColor,
}: {
  children: React.ReactNode;
  backgroundColor: GDSColor;
}) => (
  <StackLayout
    orientation={{
      '@initial': 'vertical',
      '@laptop': 'horizontal',
      '@desktop': 'horizontal',
    }}
    horizontalAlignment="center"
    css={{
      '@laptop': {
        padding: '$one $one',
      },
      '@desktop': {
        padding: '$none $five',
      },
      backgroundColor,
    }}
  >
    {children}
  </StackLayout>
);

export const Footer = ({
  footerAppDownloadAssets,
  branding,
  footerLinks,
  secondaryFooterLinks,
  footerIcons,
  backgroundColor = '$surfaceBackgroundSecondary',
  textColor,
}: {
  footerAppDownloadAssets: AssetLinkProps[];
  branding?: React.ReactNode;
  footerLinks: FooterLinkProps[];
  secondaryFooterLinks?: FooterLinkProps[];
  footerIcons?: AssetLinkProps[];
  backgroundColor?: GDSColor;
  textColor?: GDSColor;
}) => {
  const { formatMessage } = useIntl();

  return (
    <FooterLayoutWrapper backgroundColor={backgroundColor}>
      {/* Branding + App Download Links Section */}
      <StackLayout
        orientation="vertical"
        horizontalAlignment="center"
        spacing="$one"
        css={{
          flexGrow: 0,
          margin: '$one',
          '@laptop': {
            margin: '$twoAndHalf',
          },
          '@desktop': {
            margin: '$three $none',
          },
        }}
      >
        {!!branding && branding}
        <StackLayout spacing="$half">
          {footerAppDownloadAssets.map((item) => (
            <AssetLink
              key={item.url}
              src={item.src}
              alt={item.alt}
              url={item.url}
            />
          ))}
        </StackLayout>
      </StackLayout>

      {/* External and Social Links and Disclaimer Section */}
      <StackLayout
        orientation="vertical"
        horizontalAlignment={{
          '@initial': 'center',
          '@laptop': 'stretch',
          '@desktop': 'stretch',
        }}
        css={{
          margin: '$one',
          maxWidth: CONTENT_MAX_WIDTH,
          '@laptop': {
            margin: '$twoAndHalf $one',
          },
          '@desktop': {
            margin: '$three 20px',
          },
        }}
      >
        <FooterLinksAndIconsSection
          footerLinks={footerLinks}
          secondaryFooterLinks={secondaryFooterLinks}
          footerIcons={footerIcons}
          textColor={textColor}
        />
        <ParagraphText size="xs" css={{ color: '$onSurfaceTextSubdued' }}>
          {formatMessage({ id: 'STR_FOOTER_COPYRIGHT' })}
        </ParagraphText>
      </StackLayout>
    </FooterLayoutWrapper>
  );
};
