import { ErrorStateProps } from '@leagueplatform/web-common-components';
import { useIntl } from '@leagueplatform/locales';

export enum ERROR_TYPES {
  API_ERROR = 'api_error',
  NO_CONNECTION = 'no_connection',
}

export type ErrorMessageProps = {
  errorType?: ERROR_TYPES;
  buttonId?: string;
  headingString?: string;
  bodyString?: string;
  buttonAction?: () => void;
};

type ErrorBody = {
  headingId: string;
  bodyId: string;
  buttonId?: string;
  buttonAction?: () => void;
};

export const useErrorMessage = ({
  errorType = ERROR_TYPES.API_ERROR,
  buttonId,
  headingString,
  bodyString,
  buttonAction,
}: ErrorMessageProps) => {
  const { formatMessage } = useIntl();

  const ErrorMessages: {
    [type in ERROR_TYPES]: ErrorBody;
  } = {
    [ERROR_TYPES.API_ERROR]: {
      headingId: formatMessage({ id: 'STR_ERROR_STATE_HEADING_GENERIC' }),
      bodyId: formatMessage({ id: 'STR_ERROR_STATE_BODY_API' }),
      buttonId: formatMessage({ id: 'STR_RELOAD' }),
      buttonAction: () => window.location.reload(),
    },
    [ERROR_TYPES.NO_CONNECTION]: {
      headingId: formatMessage({ id: 'STR_ERROR_STATE_HEADING_GENERIC' }),
      bodyId: formatMessage({ id: 'STR_ERROR_STATE_BODY_OFFLINE' }),
    },
  };

  let errorContentData: ErrorStateProps = {
    headingString: ErrorMessages[errorType].headingId || headingString,
    bodyString: ErrorMessages[errorType].bodyId || bodyString,
  };

  if (
    ErrorMessages[errorType].buttonId &&
    ErrorMessages[errorType].buttonAction
  ) {
    errorContentData = {
      ...errorContentData,
      buttonText: buttonId || ErrorMessages[errorType].buttonId,
      onButtonClick: buttonAction || ErrorMessages[errorType].buttonAction,
    };
  }

  return errorContentData;
};
