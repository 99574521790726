import { useEffect, useState } from 'react';

export const useOnboarding = () => {
  const [shouldShowFeatureHighlights, setShouldShowFeatureHighlights] =
    useState(false);

  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    // TODO: Update to use backend flag: https://everlong.atlassian.net/browse/PREM-143
    setShouldShowFeatureHighlights(
      window.localStorage.getItem('showFeatureHighlights') !== 'false',
    );
    setLoading(false);
  }, []);

  const onOnboardingComplete = (error?: Error) => {
    if (!error) {
      window.localStorage.setItem('showFeatureHighlights', 'false');
    }
    setShouldShowFeatureHighlights(false);
  };

  return {
    shouldShowFeatureHighlights,
    isLoading,
    onOnboardingComplete,
  };
};
