import { getModulePath, LEAGUE_MODULE_NAMES } from '@leagueplatform/core';
import { APP_MANAGED_ROUTES } from 'common/app-managed-routes';
import { NavItem } from '@xo-health-web/shared-module';

export enum NavIds {
  HOME = 'HOME',
  ADVOCATE = 'ADVOCATE',
  BENEFITS = 'BENEFITS',
  CARE = 'GET_CARE',
  JOURNEY = 'JOURNEY',
}

export enum LinkType {
  NAV = 'navLink',
  LINK = 'link',
}

export interface NavLink extends NavItem {
  message_id: NavIds;
  type: LinkType;
}

export const useNavLinksList = () => {
  const navLinksList: NavLink[] = [
    {
      to: APP_MANAGED_ROUTES.HOME,
      message_id: NavIds.HOME,
      type: LinkType.NAV,
      icon: 'illustrativeHome',
    },
    {
      to: getModulePath(LEAGUE_MODULE_NAMES.wallet),
      message_id: NavIds.BENEFITS,
      type: LinkType.NAV,
      icon: 'capabilityWallet',
    },
    {
      to: getModulePath(LEAGUE_MODULE_NAMES.careDiscovery),
      message_id: NavIds.CARE,
      type: LinkType.NAV,
      icon: 'illustrativeHeart',
    },
    {
      to: getModulePath(LEAGUE_MODULE_NAMES.healthJourney),
      message_id: NavIds.JOURNEY,
      type: LinkType.NAV,
      icon: 'capabilityJourney',
    },
    {
      to: APP_MANAGED_ROUTES.ADVOCATE,
      message_id: NavIds.ADVOCATE,
      type: LinkType.NAV,
      icon: 'illustrativeSupportQuestionChat',
    },
  ];

  return {
    navLinks: navLinksList,
  };
};
