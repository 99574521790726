import React from 'react';
import { useIntl } from '@leagueplatform/locales';
import { StackLayout, ParagraphText, Box } from '@leagueplatform/genesis-core';
import { UserInfoDataProps } from '../../common/types';
import { DATE_FORMAT_UTC } from '../../common/constants';
import { UserProfileAttributeLabel } from './user-profile-attribute-label';

export const UserProfileForm: React.FC<UserInfoDataProps> = ({
  data,
}: UserInfoDataProps) => {
  const { formatMessage, formatDate } = useIntl();

  const firstName = data.userProfile?.firstName;
  const lastName = data.userProfile?.lastName;

  return (
    <Box>
      <StackLayout spacing="$one">
        <ParagraphText size="sm">
          {formatMessage({ id: 'CONTACT_CARE_ADVOCATE' })}
        </ParagraphText>
        <UserProfileAttributeLabel
          name={formatMessage({ id: 'FIRST_NAME' })}
          value={firstName}
        />
        <UserProfileAttributeLabel
          name={formatMessage({ id: 'LAST_NAME' })}
          value={lastName}
        />
        <UserProfileAttributeLabel
          name={formatMessage({ id: 'DATE_OF_BIRTH' })}
          value={formatDate(data.userProfile?.dateOfBirth, DATE_FORMAT_UTC)}
        />
        <UserProfileAttributeLabel
          name={formatMessage({ id: 'EMAIL_ADDRESS' })}
          value={data?.email}
        />
        <UserProfileAttributeLabel
          name={formatMessage({ id: 'PHONE_NUMBER' })}
          value={data.phoneNumber}
        />
      </StackLayout>
    </Box>
  );
};
