import {
  Box,
  Checkbox,
  UtilityText,
  StackLayout,
  GDSStyleObject,
} from '@leagueplatform/genesis-core';
import { Field } from 'formik';
import React from 'react';

type FormikCheckboxProps = {
  fieldId: string;
  fieldName: string;
  fieldBody: string | React.ReactNode;
  formErrors: { [key: string]: any };
  css?: GDSStyleObject;
};

export const FormikCheckBox = ({
  fieldId,
  fieldName,
  fieldBody,
  formErrors,
  css,
}: FormikCheckboxProps) => (
  <StackLayout orientation="horizontal" css={{ ...css }}>
    <Box>
      <Field
        id={fieldId}
        name={fieldName}
        as={Checkbox}
        type="Checkbox"
        error={formErrors[fieldName]}
        css={{
          '.GDS-checkbox-label': { gap: '$none' },
          marginRight: '$half',
        }}
      />
    </Box>
    <UtilityText>{fieldBody}</UtilityText>
  </StackLayout>
);
