import React from 'react';
import { useIntl } from '@leagueplatform/locales';
import {
  MainPageContainer,
  SkeletonBox,
} from '@leagueplatform/web-common-components';
import { StackLayout } from '@leagueplatform/genesis-core';
import { useHistory } from '@leagueplatform/routing';
import { useDocumentTitle } from '@leagueplatform/web-common';
import { HeroSection } from 'components/hero-section/hero-section.component';
import { CommunicationPreferencesContent } from '@xo-health-web/shared-module';
import { useAdvocateCommunicationPreference } from 'hooks/use-advocate-communication-preference';

export const CommunicationPreferencesPage = () => {
  const { formatMessage } = useIntl();
  const history = useHistory();

  const {
    data: advocateCommunicationPreference,
    isLoading,
    isSuccess,
  } = useAdvocateCommunicationPreference();

  useDocumentTitle(formatMessage({ id: 'COMMUNICATION_PREFERENCES' }));

  if (isLoading) {
    return <SkeletonBox />;
  }

  return (
    <MainPageContainer width="100%">
      <HeroSection
        title={formatMessage({ id: 'COMMUNICATION_PREFERENCES' })}
        goBack={history.goBack}
      />
      <StackLayout>
        {isSuccess && (
          <CommunicationPreferencesContent
            advocateCommunicationPreference={advocateCommunicationPreference}
          />
        )}
      </StackLayout>
    </MainPageContainer>
  );
};
