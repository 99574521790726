import React, { useEffect } from 'react';
import { logout } from 'utils/auth/logout';
import { LoadingSpinner } from '@xo-health-web/shared-module';
import logoWithText from 'assets/logo-with-text.png';

export const AuthSignOut = () => {
  useEffect(() => {
    logout();
  }, []);

  return <LoadingSpinner logo={logoWithText} altText="XO_HEALTH_LOGO" />;
};
