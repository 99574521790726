import * as React from 'react';
import { Box } from '@leagueplatform/genesis-core';
import { ERROR_TYPES, ErrorState } from '@xo-health-web/shared-module';

function ErrorPage() {
  return (
    <Box
      css={{
        width: '100vw',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <ErrorState errorType={ERROR_TYPES.API_ERROR} />
    </Box>
  );
}

export default ErrorPage;
