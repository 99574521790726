/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { GDSStackLayoutProps, StackLayout } from '@leagueplatform/genesis-core';
import {
  ErrorState as WebCommonErrorState,
  ErrorStateProps as WebCommonErrorStateProps,
} from '@leagueplatform/web-common-components';
import {
  ERROR_TYPES,
  useErrorMessage,
} from '../../hooks/use-error-message/use-error-message';
import { FullScreenContainer } from '../full-screen-container.component';

export type ErrorStateProps = WebCommonErrorStateProps & {
  isFullPage?: boolean;
  styleProps?: Partial<GDSStackLayoutProps>;
  errorType?: ERROR_TYPES;
  hideButton?: boolean;
  headingString?: string;
  bodyString?: string;
  buttonId?: string;
  buttonAction?: () => void;
};

export const ErrorState = ({
  styleProps,
  isFullPage = false,
  hideButton = false,
  ...errorData
}: ErrorStateProps) => {
  const errorContent = useErrorMessage(errorData);

  if (isFullPage) {
    return (
      <FullScreenContainer horizontalAlignment="center">
        <StackLayout
          verticalAlignment="center"
          {...styleProps}
          css={{
            textAlign: 'center',
            maxWidth: '350px',
            ...styleProps?.css,
          }}
        >
          <WebCommonErrorState hideButton={hideButton} {...errorContent} />
        </StackLayout>
      </FullScreenContainer>
    );
  }

  return (
    <StackLayout
      {...styleProps}
      css={{
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        ...styleProps?.css,
      }}
    >
      <WebCommonErrorState {...errorContent} />
    </StackLayout>
  );
};
