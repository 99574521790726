import { EVENT_NAME, trackAnalyticsEvent } from '@leagueplatform/analytics';
import {
  GDSStyleObject,
  GDSTextActionProps,
  TextAction,
} from '@leagueplatform/genesis-core';
import React from 'react';
import { Link } from '@leagueplatform/routing';
import { isExternalUrl } from '@leagueplatform/web-common';
import { useIsAuthenticated } from '../../../hooks/use-is-authenticated.hook';
import { SUB_PRODUCT_AREA_NAMES } from '../../../common/constants';

export interface FooterLinkProps {
  text: string;
  url?: string;
  onClick?: () => void;
  linkProps?: GDSTextActionProps<'a' | Link>;
}

export const FooterLinkItems = ({
  linkItems,
  styleProps,
}: {
  linkItems: FooterLinkProps[];
  styleProps?: GDSStyleObject;
}) => {
  const { isAuthenticated } = useIsAuthenticated();

  const trackAnalytics = (eventName: EVENT_NAME, targetDetail: string) => {
    trackAnalyticsEvent(eventName, {
      detail: targetDetail,
      sub_product_area: SUB_PRODUCT_AREA_NAMES.FOOTER,
    });
  };

  const handleClick = (event: React.MouseEvent, item: FooterLinkProps) => {
    if (isAuthenticated) {
      trackAnalytics(
        item.url ? EVENT_NAME.RESOURCE_CLICKED : EVENT_NAME.BUTTON_CLICKED,
        item.text,
      );
    }
    if (item.onClick) {
      event.preventDefault();
      item.onClick();
    }
  };

  return (
    <>
      {linkItems.map((item) =>
        item.url ? (
          <TextAction
            as={isExternalUrl(item.url) ? 'a' : Link}
            size="medium"
            css={styleProps}
            key={item.text}
            href={item.url}
            to={item.url}
            onClick={(event: React.MouseEvent<HTMLAnchorElement>) =>
              handleClick(event, item)
            }
            target={isExternalUrl(item.url) ? '_blank' : undefined}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...item.linkProps}
          >
            {item.text}
          </TextAction>
        ) : null,
      )}
    </>
  );
};
