import { BoxProps } from '@leagueplatform/genesis-commons';
import { useIntl } from '@leagueplatform/locales';
import type { Link } from 'common/types';
import { EXTERNAL_LINKS } from '@xo-health-web/shared-module';
import { APP_MANAGED_ROUTES } from 'common/app-managed-routes';

export type Options = {
  heading?: string;
  items: Link[];
};

const boldTextLink: BoxProps = {
  color: 'onSurface.text.subdued',
  fontWeight: 'bold',
};

const boldTextCriticalLink: BoxProps = {
  color: 'onSurface.text.critical',
  fontWeight: 'bold',
};

// TODO: need to refactor: linksProps is used for web(as there is no color property available in UserDropDownComponent) and color props is used for mobile screen
export function useUserDropdownConfig(
  handleDeleteAccount: () => void,
): Options[] {
  const { formatMessage } = useIntl();

  return [
    {
      heading: 'ACCOUNT_SETTINGS',
      items: [
        {
          text: formatMessage({ id: 'PERSONAL_INFO' }),
          to: APP_MANAGED_ROUTES.PERSONAL_INFO,
          linkProps: boldTextLink,
          color: '$onSurfaceTextSubdued',
        },
        {
          text: formatMessage({ id: 'COMMUNICATION_PREFERENCES' }),
          to: APP_MANAGED_ROUTES.COMMUNICATION_PREFERENCES,
          linkProps: boldTextLink,
          color: '$onSurfaceTextSubdued',
        },
      ],
    },
    {
      heading: 'ABOUT',
      items: [
        {
          text: formatMessage({ id: 'PRIVACY_POLICY' }),
          to: EXTERNAL_LINKS.PRIVACY_POLICY,
          target: '_blank',
          linkProps: boldTextLink,
          color: '$onSurfaceTextSubdued',
        },
        {
          text: formatMessage({ id: 'TERMS_OF_SERVICE' }),
          to: EXTERNAL_LINKS.TERMS_OF_USE,
          target: '_blank',
          linkProps: boldTextLink,
          color: '$onSurfaceTextSubdued',
        },
        {
          text: formatMessage({ id: 'DELETE_YOUR_ACCOUNT' }),
          onClick: handleDeleteAccount,
          linkProps: boldTextLink,
          color: '$onSurfaceTextSubdued',
        },
      ],
    },
    {
      items: [
        {
          text: formatMessage({ id: 'LOG_OUT' }),
          to: APP_MANAGED_ROUTES.SIGN_OUT,
          color: '$onSurfaceTextCritical',
          linkProps: boldTextCriticalLink,
        },
      ],
    },
  ];
}
