import React from 'react';
import { TextAction } from '@leagueplatform/genesis-core';
import { EVENT_NAME, trackAnalyticsEvent } from '@leagueplatform/analytics';
import { SUB_PRODUCT_AREA_NAMES } from '../../common/constants';
import { useIsAuthenticated } from '../../hooks/use-is-authenticated.hook';

export interface AssetLinkProps {
  src: string;
  alt: string;
  url: string;
}

export const AssetLink = ({ src, alt, url }: AssetLinkProps) => {
  const { isAuthenticated } = useIsAuthenticated();

  const trackAnalytics = (targetDetail: string) => {
    if (isAuthenticated) {
      trackAnalyticsEvent(EVENT_NAME.RESOURCE_CLICKED, {
        detail: targetDetail,
        sub_product_area: SUB_PRODUCT_AREA_NAMES.FOOTER,
      });
    }
  };

  return (
    <TextAction
      href={url}
      onClick={() => trackAnalytics(alt)}
      target="_blank"
      css={{
        border: 'none',
        '&:hover': {
          backgroundColor: 'transparent !important',
        },
      }}
    >
      <img src={src} alt={alt} />
    </TextAction>
  );
};
