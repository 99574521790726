import { deserialise } from 'kitsu-core';
import { captureAppError } from '@leagueplatform/observability';
import { AdvocateCommunicationPreference } from '@xo-health-web/shared-module';
import { Auth } from '@leagueplatform/auth';
import { SENTRY_CONSTANTS } from 'common/constants';

export const GET_ADVOCATTE_COMMUNICATION_PREFERENCE =
  'v1/communication-preferences';

export async function advocateCommunicationPreference() {
  const token = await Auth.getToken();

  const response = await fetch(
    `${import.meta.env.VITE_API_URL}/${GET_ADVOCATTE_COMMUNICATION_PREFERENCE}`,
    {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );

  const responseObject = await response.json();
  const { data } = deserialise(responseObject);

  if (!response.ok) {
    captureAppError(
      new Error('Failed to get advocate communication preference data'),
      {
        ownershipTeam: SENTRY_CONSTANTS.OWNERSHIP_TEAM,
      },
    );
    throw new Error('Failed to get advocate communication preference data');
  }

  const communicationPreference: AdvocateCommunicationPreference = {
    sectionName: data[0].sectionName,
    communicationMethodCode:
      data[0].preferences[0].attributes.preferredMethod.communicationMethodCode,
    description: data[0].preferences[0].attributes.description,
  };

  return communicationPreference;
}
