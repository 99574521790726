import * as React from 'react';
import { Box, Spinner } from '@leagueplatform/genesis-core';

// This is temp. fix and will be removed and replaced  with leagueplatform/lottie once the app specific loading animation is provided.

export const CelebrationCyclingAnimationLoadingSpinner = () => (
  <Box
    css={{
      position: 'relative',
      bottom: '250px',
    }}
  >
    <Spinner
      thickness={3}
      size="$three"
      tint="$decorativeBrandSecondaryDark"
      loading
    />
  </Box>
);
