import * as React from 'react';
import {
  AppBarItem,
  AppBarNavLink,
} from '@leagueplatform/web-common-components';
import {
  Image,
  TextAction,
  queryHelpers,
  useMediaQuery,
  Modal,
  Box,
} from '@leagueplatform/genesis-core';
import {
  HeaderNavBar,
  MobileNavBar,
  HEADER_NAV_BAR_DEFAULT_HEIGHT,
} from '@xo-health-web/shared-module';
import { useIntl } from '@leagueplatform/locales';
import { Link } from '@leagueplatform/routing';
import { EVENT_NAME, trackAnalyticsEvent } from '@leagueplatform/analytics';
import logoWithText from 'assets/logo-with-text.png';
import { logout } from 'utils/auth/logout';
import { APP_MANAGED_ROUTES } from 'common/app-managed-routes';
import { useUserDropdownConfig } from 'hooks/use-user-dropdown-config.hook';
import { useModal } from 'hooks/use-modal.hook';
import { UserDropdown } from './user-dropdown/user-dropdown.component';
import { useNavLinksList } from './use-nav-links-list.hook';
import { DeleteAccountModal } from '../modals/delete-account-modal.component';
import { AccountDeletedModal } from '../modals/account-deleted-modal.component';

export const APP_HEADER_NAV_DEFAULT_HEIGHT = HEADER_NAV_BAR_DEFAULT_HEIGHT;

export const AppHeaderNavBar = React.memo(() => {
  const { formatMessage } = useIntl();
  const { navLinks } = useNavLinksList();

  const sendNavBarAnalyticsEvent = (action: string) => () =>
    trackAnalyticsEvent(EVENT_NAME.NAVIGATION_TAB_CLICKED, {
      screen_name: 'Navigation Bar',
      detail: action,
    });

  const {
    isShowing: showDeleteAccountModal,
    toggle: toggleDeleteAccountModal,
  } = useModal();
  const {
    isShowing: showAccountDeletedModal,
    toggle: toggleAccountDeletedModal,
  } = useModal();

  const isMobile = useMediaQuery(queryHelpers.down('mobileLandscape'));

  const userDropdownItems = useUserDropdownConfig(toggleDeleteAccountModal);

  const logo = (
    <TextAction
      as={Link}
      to={APP_MANAGED_ROUTES.HOME}
      onClick={sendNavBarAnalyticsEvent('HOME')}
    >
      <Image
        src={logoWithText}
        height="40px"
        alt={formatMessage({ id: 'XO_HEALTH_LOGO' })}
      />
    </TextAction>
  );

  return (
    <Box>
      <HeaderNavBar>
        {!isMobile && (
          <>
            <AppBarItem margin="auto" paddingRight="two">
              {logo}
            </AppBarItem>

            {/* App Nav Home & Capability Areas */}
            {navLinks.map((navLink) => (
              <AppBarNavLink
                to={navLink.to}
                key={navLink.message_id}
                onClick={sendNavBarAnalyticsEvent(navLink.message_id)}
                color="onSurface.text.subdued"
                hoverStyle={{ color: 'onSurface.text.primary' }}
                minWidth="85px"
                justifyContent="center"
                fontWeight="bold"
              >
                {formatMessage({ id: navLink.message_id })}
              </AppBarNavLink>
            ))}

            {/* Spacer */}
            <AppBarItem aria-hidden flexGrow={20} />

            {/* App Nav User Dropdown */}
            <AppBarItem minWidth="150px" justifyContent="end">
              <UserDropdown handleDeleteAccount={toggleDeleteAccountModal} />
            </AppBarItem>
          </>
        )}
        {isMobile && (
          <MobileNavBar
            navLinks={navLinks}
            settingsLinks={userDropdownItems}
            logo={logo}
          />
        )}
      </HeaderNavBar>
      <Modal.Root
        open={showDeleteAccountModal}
        onOpenChange={toggleDeleteAccountModal}
      >
        {/* Delete the account and show the confirmation modal. */}
        <DeleteAccountModal onDeleted={toggleAccountDeletedModal} />
      </Modal.Root>
      <Modal.Root
        open={showAccountDeletedModal}
        onOpenChange={() => {
          // Account deletion modal has been shown.  Log the user out after they close it.
          toggleAccountDeletedModal();
          logout();
        }}
      >
        <AccountDeletedModal />
      </Modal.Root>
    </Box>
  );
});
