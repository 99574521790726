import React from 'react';
import { useIntl } from '@leagueplatform/locales';
import { StackLayout, UtilityText } from '@leagueplatform/genesis-core';
import { useDocumentTitle } from '@leagueplatform/web-common';
import { EXTERNAL_LINKS } from '../../../common/links';
import { BodyContainer } from '../../body-container/body-container.component';
import { CheckConfirmation } from '../../check-confirmation/check-confirmation.component';
import { CaptionWithLink } from '../../caption-with-link.component/caption-with-link.component';
import { useCommunicationPreferences } from '../../../hooks/use-communication-preferences.hook';
import { AdvocateCommunicationPreferences } from './advocate-communication-preferences.component';
import { AdvocateCommunicationPreferenceProps } from '../../../common/types';

const CONTAINER_MAX_WIDTH = '816px';

export const CommunicationPreferencesContent = ({
  advocateCommunicationPreference,
}: AdvocateCommunicationPreferenceProps) => {
  const { formatMessage } = useIntl();
  useDocumentTitle(formatMessage({ id: 'COMMUNICATION_PREFERENCES' }));

  const [
    { allowEmailNotification, isLoading, isWaiting, email, showEmail },
    { setEmailNotification },
  ] = useCommunicationPreferences();

  return (
    <BodyContainer css={{ maxWidth: CONTAINER_MAX_WIDTH }}>
      <CheckConfirmation
        label={formatMessage({
          id: 'COMMUNICATION_PREFERENCES_ALLOW_EMAIL',
        })}
        name="allowEmailNotification"
        checked={allowEmailNotification}
        handleCheck={setEmailNotification}
        loading={isLoading || isWaiting === 'email'}
        ariaDisabled={!!isWaiting}
      />
      <StackLayout
        spacing="$oneAndHalf"
        aria-live="polite"
        css={{ paddingX: '$twoAndHalf', marginBlockStart: '-$half' }}
      >
        {showEmail && (
          <StackLayout spacing="$half">
            <UtilityText
              as="p"
              css={{
                fontWeight: '$subtitleTwo',
                fontSize: '$subtitleTwo',
                lineHeight: '$subtitleTwo',
              }}
            >
              {formatMessage({
                id: 'USER_NOTIFIED_EMAIL_SUBTITLE',
              })}
            </UtilityText>
            <UtilityText
              as="p"
              css={{
                fontWeight: '$bodyTwo',
                fontSize: '$bodyTwo',
                lineHeight: '$bodyTwo',
              }}
            >
              {email}
            </UtilityText>
            <UtilityText
              as="p"
              css={{
                fontWeight: '$label',
                fontSize: '$label',
                lineHeight: '$label',
              }}
            >
              {formatMessage({
                id: 'EDIT_EMAIL',
              })}
            </UtilityText>
          </StackLayout>
        )}
        <CaptionWithLink
          messageId="EMAIL_LEGAL_DISCLAIMER"
          linkHref={EXTERNAL_LINKS.TERMS_OF_USE}
          linkTextId="VISIT_OUR_TERMS"
          css={{
            fontWeight: '$label',
            fontSize: '$label',
            lineHeight: '$label',
          }}
        />
      </StackLayout>
      <AdvocateCommunicationPreferences
        advocateCommunicationPreference={advocateCommunicationPreference}
      />
    </BodyContainer>
  );
};
