import { usePreferredFirstName } from 'hooks/use-preferred-first-name.hook';
import { useUserDropdownConfig } from 'hooks/use-user-dropdown-config.hook';

export const useUserDropdownComponent = (handleDeleteAccount: () => void) => {
  const { data, isLoading, error } = usePreferredFirstName();

  const userDropdownConfig = useUserDropdownConfig(handleDeleteAccount);

  const dropdownMenuItemProps = {
    preferredFirstName: data.preferredFirstName,
    menuItemsConfig: userDropdownConfig,
  };

  return { data: dropdownMenuItemProps, isLoading, error };
};
