import { socketAsFetch } from '../utilities/socket-as-fetch';

const ACCEPT_MEMBER_TERMS_TYPE: string = 'accept_member_terms';

const MOCK_TERMS_VERSION: string = '2.0';

export const acceptUserTerms = () =>
  socketAsFetch({
    message_type: ACCEPT_MEMBER_TERMS_TYPE,
    info: {
      version: MOCK_TERMS_VERSION,
    },
  });
