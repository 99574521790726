import { lazy } from 'react';

export const Home = lazy(() => import('pages/home/Home.page'));

export const Advocate = lazy(() => import('pages/AdvocatePage'));

export const CareCollectionsRoutes = lazy(() =>
  import('@leagueplatform/care-collections').then((module) => ({
    default: module.CareCollectionsRoutes,
  })),
);
export const DashboardRoutes = lazy(() =>
  import('@leagueplatform/dashboard').then((module) => ({
    default: module.DashboardRoutes,
  })),
);

export const CareDiscoveryRoutes = lazy(() =>
  import('@leagueplatform/care-discovery').then((module) => ({
    default: module.CareDiscoveryRoutes,
  })),
);
export const HealthJourneyRoutes = lazy(() =>
  import('@leagueplatform/health-journey').then((module) => ({
    default: module.HealthJourneyRoutes,
  })),
);
export const HealthNeedPages = lazy(() =>
  import('@leagueplatform/care-collections').then((module) => ({
    default: module.HealthNeedPages,
  })),
);

export const WalletRoutes = lazy(() =>
  import('@leagueplatform/wallet').then((module) => ({
    default: module.WalletRoutes,
  })),
);

export const ClaimsRoutes = lazy(() =>
  import('@leagueplatform/claims').then((module) => ({
    default: module.ClaimsRoutes,
  })),
);

export const AboutMeRoutes = lazy(() =>
  import('@leagueplatform/about-me').then((module) => ({
    default: module.AboutMeRoutes,
  })),
);

export const OnboardingRoutesWithProvider = lazy(() =>
  import('@leagueplatform/onboarding').then((module) => ({
    default: module.OnboardingRoutesWithProvider,
  })),
);

export const MessagingRoutes = lazy(() =>
  import('@leagueplatform/messaging').then((module) => ({
    default: module.MessagingRoutes,
  })),
);

export const AchievementsRoutes = lazy(() =>
  import('@leagueplatform/rewards-achievements').then((module) => ({
    default: module.AchievementsRoutes,
  })),
);
